import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { VendorsCategoryList } from '@zola/zola-ui/src/components/VendorsCategoryList/VendorsCategoryList';
import VendorsCategoryTiles from '@zola/zola-ui/src/components/VendorsCategoryTiles/VendorsCategoryTiles';
import { TileLink } from '@zola/zola-ui/src/components/VendorsCategoryTiles/VendorsCategoryTiles.styles';
import { COLORS3, MEDIA_QUERY } from '@zola/zola-ui/src/styles/emotion';

export const VendorCategoriesContainer = styled.div`
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  padding: 32px 40px;
  border-top: 1px solid ${COLORS3.BLACK_030};

  ${MEDIA_QUERY.DESKTOP_MASSIVE} {
    border-left: 1px solid ${COLORS3.BLACK_030};
    border-right: 1px solid ${COLORS3.BLACK_030};
  }

  ${MEDIA_QUERY.MOBILE} {
    padding: 32px 24px;
  }
`;

export const VendorCategoriesList = styled(VendorsCategoryList)`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  column-gap: 8px;
  row-gap: 24px;
  justify-content: center;

  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    grid-template-columns: repeat(5, 1fr);
  }

  ${MEDIA_QUERY.MOBILE} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const VendorCategoriesTiles = styled(VendorsCategoryTiles)`
  ${TileLink} {
    max-width: 100%;
  }
`;

export const Title = css`
  margin-bottom: 40px;

  ${MEDIA_QUERY.MOBILE} {
    margin-bottom: 24px;
  }
`;
