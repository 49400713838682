import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import HighlightTitle from '@zola/zola-ui/src/components/PreAuthV3/HighlightTitle';
import { mobileOnly } from '@zola/zola-ui/src/styles/emotion/mixins/responsive';
import Image from 'next/image';

import {
  ImageWrapper,
  Sidebar,
  SidebarBottomWrapper,
  SidebarDescription,
  SidebarTitleWrapper,
  TeamZSectionWrapper,
  FgImageWrapper,
  BgImgWrapper,
} from './MeetTeamZ.styles';

const ZolaCoupleDesktopWebpAlt =
  'static-preauth/images/Homepage/NewHomepage/MeetTeamZ/july2024/advice_bg.webp';
const AdviceForegroundAlt =
  '/static-preauth/images/Homepage/NewHomepage/MeetTeamZ/july2024/advice_ui.webp';

const MeetTeamZ = (): JSX.Element => {
  const sideImage = (
    <ImageWrapper>
      <BgImgWrapper>
        <Image
          src={ZolaCoupleDesktopWebpAlt}
          alt=""
          sizes="(max-width: 768px) 100vw, 66vw"
          layout="fill"
          objectFit="cover"
          objectPosition="center top"
          priority={false}
        />
      </BgImgWrapper>
      <FgImageWrapper>
        <Image
          src={AdviceForegroundAlt}
          alt="Team-z: Hi, Kai and Jay! How may I help you today?  Kai:  When should we send our save the dates?"
          sizes="(max-width: 768px) 50vw, 33vw"
          layout="fill"
          objectFit="contain"
          priority={false}
        />
      </FgImageWrapper>
    </ImageWrapper>
  );
  return (
    <TeamZSectionWrapper>
      {sideImage}
      <Sidebar>
        <SidebarTitleWrapper>
          <HighlightTitle isH1={false}>
            <>
              Meet Team-Z, your <br css={mobileOnly} />
              <span>expert advisors</span>
            </>
          </HighlightTitle>
        </SidebarTitleWrapper>
        <SidebarBottomWrapper>
          <SidebarDescription>
            They&apos;re here to help, always just a chat or email away.
          </SidebarDescription>
          <ButtonV3 component="a" href="/advisor" textTransform size="large">
            Get answers
          </ButtonV3>
        </SidebarBottomWrapper>
      </Sidebar>
    </TeamZSectionWrapper>
  );
};

export default MeetTeamZ;
