import Image from 'next/image';
import { useRef } from 'react';
import { COLORS3 as COLORS } from '@zola/zola-ui/src/styles/emotion';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import { MotionValue, motion, useTransform } from 'framer-motion';
import HighlightTitle from '@zola/zola-ui/src/components/PreAuthV3/HighlightTitle';
import { IntersectionOptions, useInView } from 'react-intersection-observer';

import ButtonV3 from '@zola/zola-ui/src/components/ButtonV3/ButtonV3';
import MessageIcon from '@zola/zola-ui/src/components/SvgIcons/Message';
import type { ButtonColorway } from '@zola/zola-ui/src/components/ButtonV3/types';

// Utils
import useRefScrollProgress from 'hooks/useRefScrollProgress';
import { useResponsiveDesign } from '@zola/zola-ui/src/contexts/ResponsiveDesignContext/ResponsiveDesignContext';

import {
  ButtonWrapper,
  ForegroundImage,
  GuestListForegroundWrapper,
  HelpSection,
  HelpTitle,
  Highlight,
  HighlightImageWrapper,
  HighlightTextWrapper,
  MobileListItem,
  MobileSidebarList,
  ProductDescription,
  ProductTitle,
  RegistryForegroundWrapper,
  RegistryForegroundColumn2,
  RegistryItemForeground,
  ScrollArea,
  SectionWrapper,
  Sidebar,
  SidebarList,
  SidebarListItem,
  UpscaledForegroundWrapper,
  VendorVenueForeground,
  WebsiteForegroundWrapper,
  PaperFontsForeground,
  PaperStampForeground,
  LinkWrapper,
  motionStyles,
} from './ProductPanel.styles';

// // Assets
import GuestListAsset from '../../../../../../public/static-preauth/images/Homepage/NewHomepage/ProductPanel/assets/guest-list.webp';
import VendorAsset from '../../../../../../public/static-preauth/images/Homepage/NewHomepage/ProductPanel/assets/vendor-facets.png';
import WebsiteAsset from '../../../../../../public/static-preauth/images/Homepage/NewHomepage/ProductPanel/july2024/website_ui.webp';
import PaperFontsAsset from '../../../../../../public/static-preauth/images/Homepage/NewHomepage/ProductPanel/assets/paper-fonts.png';
import PaperStampAsset from '../../../../../../public/static-preauth/images/Homepage/NewHomepage/ProductPanel/assets/paper-stamp.png';
import RegistryFundAsset from '../../../../../../public/static-preauth/images/Homepage/NewHomepage/ProductPanel/assets/registry-fund.png';
import RegistryGiftAsset from '../../../../../../public/static-preauth/images/Homepage/NewHomepage/ProductPanel/assets/registry-gift.webp';
import RegistryListAsset from '../../../../../../public/static-preauth/images/Homepage/NewHomepage/ProductPanel/assets/registry-list.png';

const VenuesDesktopWebp =
  '/static-preauth/images/Homepage/NewHomepage/ProductPanel/desktop/venues.webp';
const PaperDesktopWebp =
  '/static-preauth/images/Homepage/NewHomepage/ProductPanel/desktop/paper.webp';
const GuestListDesktopWebp =
  '/static-preauth/images/Homepage/NewHomepage/ProductPanel/july2024/guestlist_bg.webp';
const WebsiteDesktopWebp =
  '/static-preauth/images/Homepage/NewHomepage/ProductPanel/july2024/website_bg.webp';
const RegistryDesktopWebp =
  '/static-preauth/images/Homepage/NewHomepage/Grid/desktop/registry.webp';

type BreakpointInput = {
  isMobile: boolean;
  isTablet: boolean;
};

type BreakpointOutput = {
  parallaxInput: number[][];
  parallaxOutput: number[][];
  sections: number[];
};

const getCurrentBreakpoints = ({ isMobile, isTablet }: BreakpointInput): BreakpointOutput => {
  if (isMobile) {
    return {
      parallaxInput: [
        [0.1, 0.35],
        [0.2, 0.45],
        [0.3, 0.55],
        [0.4, 0.65],
        [0.5, 0.75],
      ],
      parallaxOutput: [
        [-5, -50],
        [-5, -50],
        [-80, -40],
        [-85, -15],
        [-80, -10],
      ],
      sections: [0.27, 0.35, 0.41, 0.45, 0.53, 0.58, 0.64, 0.7],
    };
  }

  if (isTablet) {
    return {
      parallaxInput: [
        [0.2, 0.45],
        [0.29, 0.52],
        [0.4, 0.62],
        [0.51, 0.72],
        [0.64, 0.8],
      ],
      parallaxOutput: [
        [-15, -120],
        [-15, -120],
        [-15, -100],
        [-85, -195],
        [-5, -100],
      ],
      sections: [0.29, 0.33, 0.42, 0.47, 0.54, 0.6, 0.66, 0.72],
    };
  }

  return {
    parallaxInput: [
      [-0.15, 0.45],
      [0, 0.6],
      [0.2, 0.7],
      [0.45, 0.8],
      [0.5, 1.1],
    ],
    parallaxOutput: [
      [0, -120],
      [0, -120],
      [0, -120],
      [-50, -225],
      [0, -120],
    ],
    sections: [0.2, 0.27, 0.37, 0.45, 0.55, 0.6, 0.7, 0.76],
  };
};

type ProductInfo = {
  color: string;
  nav: {
    id: string;
    title: string;
    viewComplete: boolean;
  };
  title: {
    start: string;
    highlight: string;
  };
  description: string;
  inViewRef: (node?: Element | null | undefined) => void;
  viewCompleteRef: (node?: Element | null | undefined) => void;
  buttons: {
    link: string;
    text: string;
    colorway: ButtonColorway;
  }[];
  imageComponent: React.ReactElement;
  foregroundImage: React.ReactElement;
};

type ProductPanelProps = {
  scrollYProgress: MotionValue<number>;
};

const ProductPanel: React.FC<ProductPanelProps> = ({ scrollYProgress }): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const { isMobile, isTablet, isDesktop } = useResponsiveDesign();

  const { parallaxInput, parallaxOutput, sections } = getCurrentBreakpoints({
    isMobile,
    isTablet,
  });

  const backgroundColorInput = useRefScrollProgress(ref, sections, !isDesktop);

  const backgroundColor = useTransform(scrollYProgress, backgroundColorInput, [
    COLORS.FOREST_100,
    COLORS.BAY_100,
    COLORS.BAY_100,
    COLORS.WINE_100,
    COLORS.WINE_100,
    COLORS.BAY_100,
    COLORS.BAY_100,
    COLORS.FOREST_100,
  ]);
  const image1Y = useTransform(
    scrollYProgress,
    useRefScrollProgress(ref, parallaxInput[0], !isDesktop),
    parallaxOutput[0]
  );
  const image2Y = useTransform(
    scrollYProgress,
    useRefScrollProgress(ref, parallaxInput[1], !isDesktop),
    parallaxOutput[1]
  );
  const image3Y = useTransform(
    scrollYProgress,
    useRefScrollProgress(ref, parallaxInput[2], !isDesktop),
    parallaxOutput[2]
  );
  const image4Y = useTransform(
    scrollYProgress,
    useRefScrollProgress(ref, parallaxInput[3], !isDesktop),
    parallaxOutput[3]
  );
  const image5Y = useTransform(
    scrollYProgress,
    useRefScrollProgress(ref, parallaxInput[4], !isDesktop),
    parallaxOutput[4]
  );

  const inViewOptions: IntersectionOptions = { delay: 100 };
  const [guestRef, guestInView] = useInView(inViewOptions);
  const [websiteRef, websiteInView] = useInView(inViewOptions);
  const [venuesRef, venuesInView] = useInView(inViewOptions);
  const [invitesRef, invitesInView] = useInView(inViewOptions);
  const [registryRef, registryInView] = useInView(inViewOptions);

  const viewCompleteOptions: IntersectionOptions = { delay: 500, triggerOnce: true };
  const [guestViewCompleteRef, guestViewComplete] = useInView(viewCompleteOptions);
  const [websiteViewCompleteRef, websiteViewComplete] = useInView(viewCompleteOptions);
  const [venuesViewCompleteRef, venuesViewComplete] = useInView(viewCompleteOptions);
  const [invitesViewCompleteRef, invitesViewComplete] = useInView(viewCompleteOptions);
  const [registryViewCompleteRef, registryViewComplete] = useInView(viewCompleteOptions);

  const secondaryButtonText = 'Learn more';

  /**
   * TODO: Refactor
   * Create a ProductTile component and pass these values in as props.
   * move refs into the component and have callback function tracking the current view.
   * Group parallax values into the component by breakpoint so they are easier to read and adjust.
   */
  const productTilesInfo: ProductInfo[] = [
    {
      color: COLORS.MINT_100,
      nav: {
        id: 'website',
        title: 'Free wedding websites',
        viewComplete: websiteViewComplete,
      },
      title: {
        start: 'Share it all on a',
        highlight: 'free wedding website',
      },
      description: 'The easiest, most beautiful way to keep guests in-the-know.',
      inViewRef: websiteRef,
      viewCompleteRef: websiteViewCompleteRef,
      buttons: [
        {
          link: '/wedding-planning/website',
          text: secondaryButtonText,
          colorway: 'mint',
        },
        {
          link: '/wedding-planning/website/designs',
          text: 'Explore designs',
          colorway: 'mint',
        },
      ],
      imageComponent: (
        <motion.div style={{ y: image1Y, objectPosition: 'top' }} css={motionStyles}>
          <Image
            src={WebsiteDesktopWebp}
            alt=""
            layout="fill"
            objectFit={isDesktop ? 'contain' : 'cover'}
            objectPosition={`center ${isDesktop ? '35px' : ''}`}
          />
        </motion.div>
      ),
      foregroundImage: (
        <WebsiteForegroundWrapper>
          <ForegroundImage src={WebsiteAsset} alt="" loading="lazy" />
        </WebsiteForegroundWrapper>
      ),
    },
    {
      color: COLORS.CLOUD_100,
      nav: {
        id: 'guest-list',
        title: 'Guest list',
        viewComplete: guestViewComplete,
      },
      title: {
        start: 'Get more from your',
        highlight: 'guest list',
      },
      description:
        "Decide who to celebrate with and we'll do the rest—from collecting contact info to managing RSVPs.",
      inViewRef: guestRef,
      viewCompleteRef: guestViewCompleteRef,
      buttons: [
        {
          link: '/wedding/onboard/wedding-guest-list?question=WEDDING_DATE',
          text: 'Get started',
          colorway: 'cloud',
        },
        {
          link: '/wedding-planning/guests',
          text: secondaryButtonText,
          colorway: 'cloud',
        },
      ],
      imageComponent: (
        <motion.div style={{ y: image2Y }} css={motionStyles}>
          <Image
            src={GuestListDesktopWebp}
            alt=""
            layout="fill"
            objectFit={isDesktop ? 'contain' : 'cover'}
            objectPosition={`center ${isDesktop ? '35px' : 'top'}`}
          />
        </motion.div>
      ),
      foregroundImage: (
        <GuestListForegroundWrapper>
          <ForegroundImage src={GuestListAsset} alt="" loading="lazy" />
        </GuestListForegroundWrapper>
      ),
    },
    {
      color: COLORS.LILAC_100,
      nav: {
        id: 'vendors',
        title: 'Venue and vendors',
        viewComplete: venuesViewComplete,
      },
      title: {
        start: 'Discover the best',
        highlight: 'venues and vendors',
      },
      description:
        "We'll play matchmaker. Get personalized recs for your style and budget. Reach out on Zola and book when you're ready.",
      inViewRef: venuesRef,
      viewCompleteRef: venuesViewCompleteRef,
      buttons: [
        {
          link: '/wedding-vendors',
          text: 'Search vendors near you',
          colorway: 'lilac',
        },
      ],
      imageComponent: (
        <motion.div style={{ y: image3Y }} css={motionStyles}>
          <Image src={VenuesDesktopWebp} alt="" layout="fill" objectFit="cover" />
        </motion.div>
      ),
      foregroundImage: (
        <UpscaledForegroundWrapper>
          <VendorVenueForeground
            src={VendorAsset}
            alt="Vendor filters: available on your date, in your budget, trending"
            loading="lazy"
          />
        </UpscaledForegroundWrapper>
      ),
    },
    {
      color: COLORS.CLOUD_100,
      nav: {
        id: 'invites',
        title: 'Invites and paper',
        viewComplete: invitesViewComplete,
      },
      title: {
        start: 'Look good on paper with',
        highlight: 'invites and more',
      },
      description:
        'The easiest way to get from "save the date" to "thanks so much." Designed by our artists, customized by you.',
      inViewRef: invitesRef,
      viewCompleteRef: invitesViewCompleteRef,
      buttons: [
        {
          link: '/wedding-planning/save-the-date/shop',
          text: 'Explore designs',
          colorway: 'cloud',
        },
        {
          link: '/wedding-planning/paper',
          text: secondaryButtonText,
          colorway: 'cloud',
        },
      ],
      imageComponent: (
        <motion.div style={{ y: image4Y }} css={motionStyles}>
          <Image src={PaperDesktopWebp} alt="" layout="fill" objectFit="cover" />
        </motion.div>
      ),
      foregroundImage: (
        <UpscaledForegroundWrapper>
          <PaperStampForeground src={PaperStampAsset} alt="Add foil stamp" loading="lazy" />
          <PaperFontsForeground src={PaperFontsAsset} alt="Customize fonts" loading="lazy" />
        </UpscaledForegroundWrapper>
      ),
    },
    {
      color: COLORS.MINT_100,
      nav: {
        id: 'registry',
        title: 'Registry',
        viewComplete: registryViewComplete,
      },
      title: {
        start: 'Build your',
        highlight: 'registry',
      },
      description:
        'Register for everything from zero-fee cash funds to experiences and gifts—all from just one open tab.',
      inViewRef: registryRef,
      viewCompleteRef: registryViewCompleteRef,
      buttons: [
        {
          link: '/onboard/new',
          text: 'Get started',
          colorway: 'mint',
        },
        {
          link: 'wedding-registry',
          text: secondaryButtonText,
          colorway: 'mint',
        },
      ],
      imageComponent: (
        <motion.div style={{ y: image5Y }} css={motionStyles}>
          <Image src={RegistryDesktopWebp} alt="" layout="fill" objectFit="cover" />
        </motion.div>
      ),
      foregroundImage: (
        <RegistryForegroundWrapper>
          <RegistryItemForeground
            src={RegistryGiftAsset}
            alt="Add gift: Le Creuset dutch oven"
            loading="lazy"
          />
          <RegistryForegroundColumn2 src={RegistryFundAsset} alt="Add cash fund" loading="lazy" />
          <RegistryForegroundColumn2
            src={RegistryListAsset}
            alt="Registry: Sonos speaker, Parachute towels"
            loading="lazy"
          />
        </RegistryForegroundWrapper>
      ),
    },
  ];

  const ProductTiles = productTilesInfo.map((productInfo, index) => (
    <Highlight
      key={`product-info-${index}`}
      id={productInfo.nav.id}
      style={{ color: productInfo.color }}
      tabIndex={-1}
    >
      <HighlightImageWrapper>
        {productInfo.imageComponent}
        {productInfo.foregroundImage}
      </HighlightImageWrapper>
      <HighlightTextWrapper>
        <ProductTitle>{productInfo.nav.title}</ProductTitle>
        <HighlightTitle
          isH1={isDesktop}
          hasHighlightBreak={isDesktop}
          titleStart={productInfo.title.start}
          highlightText={productInfo.title.highlight}
        />
        <ProductDescription ref={productInfo.viewCompleteRef}>
          {productInfo.description}
        </ProductDescription>
        <ButtonWrapper ref={productInfo.inViewRef}>
          {productInfo.buttons?.map((buttonInfo, buttonIndex) => (
            <ButtonV3
              key={`product-${index}-button-${buttonIndex}`}
              component="a"
              colorway={buttonInfo.colorway}
              href={buttonInfo.link}
              textTransform
              variant={buttonIndex === 0 ? 'primary' : 'secondary'}
              size="large"
            >
              {buttonInfo.text}
            </ButtonV3>
          ))}
        </ButtonWrapper>
      </HighlightTextWrapper>
    </Highlight>
  ));

  const navItems = productTilesInfo.map(({ nav }, index) => (
    <SidebarListItem key={`nav-item-${index}`} viewComplete={nav.viewComplete}>
      <LinkV2 noUnderline href={`#${nav.id}`} color="black" inline subtle>
        {nav.title}
      </LinkV2>
    </SidebarListItem>
  ));

  return (
    <SectionWrapper>
      <ScrollArea ref={ref}>
        <MobileSidebarList>
          <MobileListItem backgroundColor={COLORS.MINT_100} isInView={websiteInView} />
          <MobileListItem backgroundColor={COLORS.CLOUD_100} isInView={guestInView} />
          <MobileListItem backgroundColor={COLORS.LILAC_100} isInView={venuesInView} />
          <MobileListItem backgroundColor={COLORS.CLOUD_100} isInView={invitesInView} />
          <MobileListItem backgroundColor={COLORS.MINT_100} isInView={registryInView} />
        </MobileSidebarList>
        <motion.div style={{ backgroundColor }}>{ProductTiles}</motion.div>
      </ScrollArea>
      <Sidebar>
        <SidebarList>{navItems}</SidebarList>
        <HelpSection>
          <HelpTitle>Need a hand?</HelpTitle>
          <LinkWrapper>
            <MessageIcon height={24} width={24} />
            <LinkV2 color="black" href="/advisor" inline>
              Chat with us
            </LinkV2>
          </LinkWrapper>
        </HelpSection>
      </Sidebar>
    </SectionWrapper>
  );
};

export default ProductPanel;
