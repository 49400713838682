import React, { Fragment, useEffect, useState } from 'react';
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import ArrowIcon from '@zola/zola-ui/src/components/SvgIcons/Arrow';
import { useResponsiveDesign } from '@zola/zola-ui/src/contexts/ResponsiveDesignContext/ResponsiveDesignContext';

import Image from 'next/image';

import { RESPONSIVE_CLASSES } from '@zola/zola-ui/src/styles/emotion/mixins/responsive';
import DesktopPlaceholder from '../../../../../../public/static-preauth/images/Homepage/NewHomepage/Hero/desktop-placeholder-july2024.webp';

import {
  ButtonWrapper,
  ContentContainer,
  HeroSection,
  HeroVideo,
  HeroVideoPoster,
  MediaContainer,
  MediaOverlayLinearBottom,
  MediaOverlayLinearTop,
  MediaOverlayRadial,
  Subtitle,
  Title,
  TitleWrapper,
  DownArrowWrapper,
  IconButton,
  HeroImageWrapper,
} from './Hero.styles';

const MobileHeroImage = '/static-preauth/images/Homepage/NewHomepage/Hero/hero_mobile_2024.webp';

const Hero: React.FC = (): JSX.Element => {
  useEffect(() => {
    const videoElement = document.querySelector('video');
    if (videoElement) {
      videoElement.playbackRate = 0.75;
    }
  }, []);

  const onDownArrowClick = () => {
    const productGridSection = document.getElementById('product-grid');
    if (productGridSection) {
      window.scroll({
        top: productGridSection.offsetTop + (113 - 76),
        behavior: 'smooth',
      });
    }
  };

  const buttons = (
    <>
      <ButtonWrapper>
        <ButtonV3
          component="a"
          textTransform
          variant="secondary"
          href="/wedding/onboard/wedding-planning"
          size="large"
          fullWidth
        >
          Let&apos;s go
        </ButtonV3>
        <ButtonV3
          component="a"
          href="/search/wedding-registry"
          textTransform
          variant="primary"
          size="large"
          fullWidth
        >
          Find a couple
        </ButtonV3>
      </ButtonWrapper>

      <DownArrowWrapper>
        <IconButton variant="secondary" colorway="cloudAlt" size="large" onClick={onDownArrowClick}>
          <ArrowIcon direction="down" />
        </IconButton>
      </DownArrowWrapper>
    </>
  );

  // Optimize Video Load
  const { isMobile } = useResponsiveDesign();
  const [allowVideo, setAllowVideo] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  useEffect(() => {
    setAllowVideo(!isMobile);
  }, [isMobile]);

  const videoLoaded = () => {
    setIsVideoLoaded(true);
  };

  return (
    <HeroSection>
      <MediaContainer>
        <HeroImageWrapper css={RESPONSIVE_CLASSES.mobileOnly}>
          <Image src={MobileHeroImage} alt="" layout="fill" objectFit="cover" priority />
        </HeroImageWrapper>
        {allowVideo && (
          <Fragment>
            <HeroVideoPoster
              src={DesktopPlaceholder}
              alt=""
              style={{ display: isVideoLoaded ? 'none' : 'block' }}
            />
            <HeroVideo
              autoPlay
              muted
              loop
              playsInline
              onCanPlayThrough={videoLoaded}
              style={{ display: isVideoLoaded ? 'block' : 'none' }}
            >
              <source
                src="https://d1tntvpcrzvon2.cloudfront.net/static-assets/videos/landing-pages/homepage_hero_july2024_optimized.webm"
                type="video/webm"
              />
              <source
                src="https://d1tntvpcrzvon2.cloudfront.net/static-assets/videos/landing-pages/homepage_hero_july2024_optimized.mp4"
                type="video/mp4"
              />
            </HeroVideo>
          </Fragment>
        )}
        <MediaOverlayLinearTop />
        <MediaOverlayRadial />
        <MediaOverlayLinearBottom />
      </MediaContainer>
      <ContentContainer>
        <TitleWrapper>
          <Title color="WHITE_100">Wedding planning starts here</Title>
          <Subtitle color="WHITE_100" presentation="h4">
            From venues and save the dates to a free wedding website, a registry and even your cake
            — Zola is here for all the days along the way
          </Subtitle>
        </TitleWrapper>
        {buttons}
      </ContentContainer>
    </HeroSection>
  );
};

export default Hero;
