import { Differentiation } from '@zola/zola-ui/src/components/PreAuthV3';
import { COLORS3 as COLORS } from '@zola/zola-ui/src/styles/emotion';
import type { DifferentiationProps } from '@zola/zola-ui/src/components/PreAuthV3/Differentiation';
import { differentiationStyleOverrides } from 'components/PreauthV3/NextBackgroundForegroundImages/NextBackgroundForegroundImages.styles';
import NextBackgroundForegroundImages from 'components/PreauthV3/NextBackgroundForegroundImages';

import { DifferentiationWrapper, foregroundOverrides } from './ZolaDifference.styles';

const GroupGiftingDesktopImgix =
  '/static-preauth/images/Homepage/NewHomepage/ZolaDifference/desktop/group_gifting.png';
const VirtualExchangesDesktopImgix =
  '/static-preauth/images/Homepage/NewHomepage/ZolaDifference/desktop/virtual_exchanges.png';
const SeatingChartDesktopImgix =
  '/static-preauth/images/Homepage/NewHomepage/ZolaDifference/desktop/seating_charts.png';
const DiscountDesktopImgix =
  '/static-preauth/images/Homepage/NewHomepage/ZolaDifference/desktop/discount.png';
const GuestListDesktopImgix =
  '/static-preauth/images/Homepage/NewHomepage/ZolaDifference/desktop/guest_list.png';

const menuItemsAlt: DifferentiationProps['content'] = [
  {
    id: 'group-gifting',
    subtitle: 'Group gifting',
    description:
      'A great way to register for those big ticket items. With multiple guests contributing toward the total, you’ll have that couch in no time!',
    imageElement: (
      <NextBackgroundForegroundImages
        bgColor={COLORS.WINE_100}
        foregroundImg={{
          src: GroupGiftingDesktopImgix,
          alt: 'Honeymoon fund... Contribute: $75.00. Still needs: $340.70.',
        }}
        priority={false}
        css={differentiationStyleOverrides}
      />
    ),
  },
  {
    id: 'virtual-exchanges',
    subtitle: 'Virtual exchanges',
    description:
      'Changing your mind is natural! Exchange gifts before they even ship for the equivalent in credit.',
    imageElement: (
      <NextBackgroundForegroundImages
        bgColor={COLORS.BAY_100}
        foregroundImg={{
          src: VirtualExchangesDesktopImgix,
          alt: "You've reveived a gift... Send now, send later, or convert to credit",
        }}
        priority={false}
        css={differentiationStyleOverrides}
      />
    ),
  },
  {
    id: 'seating-charts',
    subtitle: 'Smart seating charts',
    description:
      'Drag and drop guests into different tables for each event at your wedding. Customize table names and the number of seats.',
    imageElement: (
      <NextBackgroundForegroundImages
        bgColor={COLORS.FOREST_100}
        foregroundImg={{
          src: SeatingChartDesktopImgix,
          alt: '',
        }}
        priority={false}
        css={differentiationStyleOverrides}
      />
    ),
  },
  {
    id: 'discount',
    subtitle: '20% off post-wedding',
    description:
      'Could newlywed life get any sweeter? Grab items you forgot on your registry, or treat yourselves to something special down the road.',
    imageElement: (
      <NextBackgroundForegroundImages
        bgColor={COLORS.ROSE_100}
        foregroundImg={{
          src: DiscountDesktopImgix,
          alt: '',
        }}
        priority={false}
        css={differentiationStyleOverrides}
      />
    ),
  },
  {
    id: 'guest-list',
    subtitle: 'A powerful guest list',
    description:
      'The guest list, reinvented. Import a spreadsheet or start fresh on Zola. Track RSVPs, meal selections, plus ones, and more.',
    imageElement: (
      <NextBackgroundForegroundImages
        bgColor={COLORS.MARINE_100}
        foregroundImg={{
          src: GuestListDesktopImgix,
          alt: '',
        }}
        priority={false}
        css={differentiationStyleOverrides}
      />
    ),
  },
];

const ZolaDifference: React.FC = (): JSX.Element => {
  const titleProps = {
    titleStart: 'The',
    highlightText: 'Zola difference',
    titleEnd: 'is in the details',
  };

  return (
    <DifferentiationWrapper css={foregroundOverrides}>
      <Differentiation content={menuItemsAlt} title={titleProps} />
    </DifferentiationWrapper>
  );
};

export default ZolaDifference;
