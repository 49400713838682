type UsState = {
  name: string;
  stateCode: string;
  slug: string;
};

export const US_STATES: UsState[] = [
  { name: 'Alabama', stateCode: 'AL', slug: 'alabama' },
  { name: 'Alaska', stateCode: 'AK', slug: 'alaska' },
  { name: 'Arizona', stateCode: 'AZ', slug: 'arizona' },
  { name: 'Arkansas', stateCode: 'AR', slug: 'arkansas' },
  { name: 'California', stateCode: 'CA', slug: 'california' },
  { name: 'Colorado', stateCode: 'CO', slug: 'colorado' },
  { name: 'Connecticut', stateCode: 'CT', slug: 'connecticut' },
  { name: 'Delaware', stateCode: 'DE', slug: 'delaware' },
  { name: 'Florida', stateCode: 'FL', slug: 'florida' },
  { name: 'Georgia', stateCode: 'GA', slug: 'georgia' },
  { name: 'Hawaii', stateCode: 'HI', slug: 'hawaii' },
  { name: 'Idaho', stateCode: 'ID', slug: 'idaho' },
  { name: 'Illinois', stateCode: 'IL', slug: 'illinois' },
  { name: 'Indiana', stateCode: 'IN', slug: 'indiana' },
  { name: 'Iowa', stateCode: 'IA', slug: 'iowa' },
  { name: 'Kansas', stateCode: 'KS', slug: 'kansas' },
  { name: 'Kentucky', stateCode: 'KY', slug: 'kentucky' },
  { name: 'Louisiana', stateCode: 'LA', slug: 'louisiana' },
  { name: 'Maine', stateCode: 'ME', slug: 'maine' },
  { name: 'Maryland', stateCode: 'MD', slug: 'maryland' },
  { name: 'Massachusetts', stateCode: 'MA', slug: 'massachusetts' },
  { name: 'Michigan', stateCode: 'MI', slug: 'michigan' },
  { name: 'Minnesota', stateCode: 'MN', slug: 'minnesota' },
  { name: 'Mississippi', stateCode: 'MS', slug: 'mississippi' },
  { name: 'Missouri', stateCode: 'MO', slug: 'missouri' },
  { name: 'Montana', stateCode: 'MT', slug: 'montana' },
  { name: 'Nebraska', stateCode: 'NE', slug: 'nebraska' },
  { name: 'Nevada', stateCode: 'NV', slug: 'nevada' },
  { name: 'New Hampshire', stateCode: 'NH', slug: 'new-hampshire' },
  { name: 'New Jersey', stateCode: 'NJ', slug: 'new-jersey' },
  { name: 'New Mexico', stateCode: 'NM', slug: 'new-mexico' },
  { name: 'New York', stateCode: 'NY', slug: 'new-york' },
  { name: 'North Carolina', stateCode: 'NC', slug: 'north-carolina' },
  { name: 'North Dakota', stateCode: 'ND', slug: 'north-dakota' },
  { name: 'Ohio', stateCode: 'OH', slug: 'ohio' },
  { name: 'Oklahoma', stateCode: 'OK', slug: 'oklahoma' },
  { name: 'Oregon', stateCode: 'OR', slug: 'oregon' },
  { name: 'Pennsylvania', stateCode: 'PA', slug: 'pennsylvania' },
  { name: 'Rhode Island', stateCode: 'RI', slug: 'rhode-island' },
  { name: 'South Carolina', stateCode: 'SC', slug: 'south-carolina' },
  { name: 'South Dakota', stateCode: 'SD', slug: 'south-dakota' },
  { name: 'Tennessee', stateCode: 'TN', slug: 'tennessee' },
  { name: 'Texas', stateCode: 'TX', slug: 'texas' },
  { name: 'Utah', stateCode: 'UT', slug: 'utah' },
  { name: 'Vermont', stateCode: 'VT', slug: 'vermont' },
  { name: 'Virginia', stateCode: 'VA', slug: 'virginia' },
  { name: 'Washington', stateCode: 'WA', slug: 'washington' },
  { name: 'Washington, DC', stateCode: 'DC', slug: 'washington-dc' },
  { name: 'West Virginia', stateCode: 'WV', slug: 'west-virginia' },
  { name: 'Wisconsin', stateCode: 'WI', slug: 'wisconsin' },
  { name: 'Wyoming', stateCode: 'WY', slug: 'wyoming' },
];

export const stateDisplayName: Record<string, string | undefined> = US_STATES.reduce(
  (accumulator, state) => {
    accumulator[state.stateCode] = state.name;
    return accumulator;
  },
  {} as Record<string, string | undefined>
);

export const stateAbbreviationFromSlug: Record<string, string | undefined> = US_STATES.reduce(
  (accumulator, state) => {
    accumulator[state.slug] = state.stateCode;
    return accumulator;
  },
  {
    'washington-district-of-columbia': 'DC',
  } as Record<string, string | undefined>
);
