import { Title2 } from '@zola/zola-ui/src/typography/Headings';
import { useResponsiveDesign } from '@zola/zola-ui/src/contexts/ResponsiveDesignContext/ResponsiveDesignContext';
import {
  Title,
  VendorCategoriesContainer,
  VendorCategoriesList,
  VendorCategoriesTiles,
} from './VendorCategories.styles';

export const VendorCategories = () => {
  const { isMobile } = useResponsiveDesign();
  return (
    <VendorCategoriesContainer>
      <Title2 css={Title} presentation="h3">
        Explore wedding vendors by category
      </Title2>
      {isMobile ? <VendorCategoriesTiles /> : <VendorCategoriesList />}
    </VendorCategoriesContainer>
  );
};
