import styled from '@emotion/styled';
import { COLORS3 as COLORS, FONT, MEDIA_QUERY, SPACING } from '@zola/zola-ui/src/styles/emotion';

export const TeamZSectionWrapper = styled.div`
  background-color: ${COLORS.WHITE_100};
  display: grid;
  grid-template-columns: 60% 40%;
  height: calc(100vh - 130px);
  min-height: 590px;
  max-width: 1920px;

  ${MEDIA_QUERY.DESKTOP_MASSIVE} {
    margin-left: auto;
    margin-right: auto;
    border-left: 1px solid ${COLORS.BLACK_030};
    border-right: 1px solid ${COLORS.BLACK_030};
  }

  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    grid-template-columns: 1fr;
    grid-auto-rows: fit-content;
    height: fit-content;
    min-height: auto;
    padding: 56px;
    row-gap: 56px;
  }

  ${MEDIA_QUERY.MOBILE} {
    border-width: 0;
    padding: ${SPACING.LG} 0;
    row-gap: ${SPACING.S32};
  }
`;

export const ImageWrapper = styled.div`
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  min-height: 301px;

  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    width: 100%;
  }
  ${MEDIA_QUERY.DESKTOP} {
    margin: ${SPACING.MD};
  }

  ${MEDIA_QUERY.MOBILE} {
    height: 301px;
    padding: 0 ${SPACING.MD};
  }
  ${MEDIA_QUERY.TABLET} {
    min-height: 500px;
    height: auto;
    /* padding: 0 ${SPACING.MD}; */
  }
`;

export const Sidebar = styled.div`
  border-left: 1px solid ${COLORS.BLACK_030};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 64px;

  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    border: 0;
    grid-row: 1;
    padding: 0;
  }

  ${MEDIA_QUERY.MOBILE} {
    padding: 0 ${SPACING.MD};
  }
`;

export const SidebarTitleWrapper = styled.div`
  color: ${COLORS.BLACK_100};
  justify-self: center;
  width: 400px;

  ${MEDIA_QUERY.EXCLUDE_DESKTOP_LG} {
    h1 {
      font-size: ${FONT.FONT_SIZE_SEMI_HUGE};
    }
  }

  ${MEDIA_QUERY.DESKTOP} {
    width: auto;
  }

  ${MEDIA_QUERY.MOBILE} {
    width: auto;

    h2 {
      font-size: ${FONT.FONT_SIZE_LARGER};
    }
  }
`;

export const SidebarBottomWrapper = styled.div`
  margin-top: auto;

  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    margin-top: 0;
  }
`;

export const SidebarDescription = styled.div`
  align-self: left;
  font-size: ${FONT.FONT_SIZE_LARGE};
  padding: ${SPACING.LG} 0;
  width: 400px;

  ${MEDIA_QUERY.EXCLUDE_DESKTOP_LG} {
    width: auto;
  }

  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    font-size: ${FONT.FONT_SIZE_REGULAR};
    padding: ${SPACING.S24} 0;
  }
`;

export const BgImgWrapper = styled.div`
  border-radius: 16px;
  height: 100%;
  min-width: 100%;
  overflow: hidden !important;
  position: relative;
`;

export const FgImageWrapper = styled.div`
  height: 60%;
  width: 70%;
  bottom: 3%;
  right: 5%;
  position: absolute;

  ${MEDIA_QUERY.DESKTOP} {
    width: 50%;
    height: 50%;
    right: 15%;
  }

  ${MEDIA_QUERY.DESKTOP_XL} {
    right: 20%;
  }
`;
