import styled from '@emotion/styled';
import { COLORS3 as COLORS, MEDIA_QUERY } from '@zola/zola-ui/src/styles/emotion';

export const HomepageWrapper = styled.div`
  -webkit-overflow-scrolling: touch;
  height: calc(100vh - 94px);
  position: relative;
  scroll-behavior: smooth;
  scroll-margin-top: 200px;
  scroll-snap-type: y proximity;
  width: 100%;

  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    height: calc(100vh - 60px);
    overflow-y: auto;
  }
`;

export const ContentWrapper = styled.div`
  align-items: initial;
  background-color: ${COLORS.WHITE_100};
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  display: grid;
  position: sticky;

  ${MEDIA_QUERY.DESKTOP_MASSIVE} {
    border-left: 1px solid ${COLORS.BLACK_030};
    border-right: 1px solid ${COLORS.BLACK_030};
  }
`;

export const FooterWrapper = styled.div`
  ${MEDIA_QUERY.DESKTOP_MASSIVE} {
    > div {
      margin-left: auto;
      margin-right: auto;
      border-left: 1px solid ${COLORS.BLACK_030};
      border-right: 1px solid ${COLORS.BLACK_030};
    }
  }
`;

// Default export needed due to build error
// Error: Build optimization failed: found page without a React Component as default export
export default HomepageWrapper;
