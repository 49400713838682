import { MutableRefObject, useEffect, useLayoutEffect, useState } from 'react';

/**
 * Takes a component ref and returns the scroll `start` and `end` percentages
 * that are relative to the total document progress.
 * This hook should be used alongside useViewportScroll to give proper
 * values for the useTransform hook
 *
 * https://gist.github.com/coleturner/34396fb826c12fbd88d6591173d178c2?permalink_comment_id=4211920#gistcomment-4211920
 *
 * @param {MutableRefObject<HTMLElement | null>} ref - event
 * @param {number[]} keyframes - breakpoints of the ref that are important for animation shifts
 * @param {boolean} shouldReturnKeyframes - defaults to false, can be used when the scroll tracking hook is dependent on screen size. Should be true if useElementScroll is in use
 */
export default function useRefScrollProgress(
  ref: MutableRefObject<HTMLElement | null>,
  keyframes = [0, 1],
  shouldReturnKeyframes = false
) {
  const [inputRange, setInputRange] = useState<number[]>(keyframes);
  const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

  // TODO: this should probably not be wrapped in an effect hook but rather just perform a client-side check
  useIsomorphicLayoutEffect(() => {
    if (shouldReturnKeyframes || !ref.current) {
      return;
    }

    const rect = ref.current.getBoundingClientRect();
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const documentHeightOffset = document.body.clientHeight - window.innerHeight;

    const refOffset = rect.top + scrollTop - window.innerHeight;
    const refEnding = window.innerHeight + rect.height;

    const values = keyframes.map(
      viewportPercentage => (refOffset + refEnding * viewportPercentage) / documentHeightOffset
    );

    setInputRange(values);
  }, [ref, setInputRange]);

  return inputRange;
}
