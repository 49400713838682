import styled from '@emotion/styled';
import {
  COLORS3 as COLORS,
  FONT,
  MEDIA_QUERY,
  SPACING,
  ZINDEX,
} from '@zola/zola-ui/src/styles/emotion';
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import H from '@zola/zola-ui/src/typography/Headings';
import { keyframes } from '@emotion/react';

export const HeroSection = styled.section`
  display: flex;
  height: calc(100vh - 150px);
  justify-content: center;
  z-index: ${ZINDEX.bottom};

  ${MEDIA_QUERY.TABLET} {
    height: calc(100vh - 100px);
  }

  ${MEDIA_QUERY.MOBILE} {
    height: 480px;
  }
`;

export const MediaContainer = styled.div`
  background-color: ${COLORS.BLACK_100};
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: ${ZINDEX.bottom};

  ${MEDIA_QUERY.MOBILE} {
    height: inherit;
  }
`;

const sharedOverlayLinearStyles = `
  position: absolute;
  width: 100%;
  opacity: 0.3;

  ${MEDIA_QUERY.MOBILE} {
    opacity: 0.5;
  }
`;

export const MediaOverlayLinearTop = styled.div`
  top: 0;
  height: 100px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, ${COLORS.BLACK_100} 100%);
  ${sharedOverlayLinearStyles}
`;

export const MediaOverlayLinearBottom = styled.div`
  bottom: 0;
  height: 200px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, ${COLORS.BLACK_100} 100%);
  ${sharedOverlayLinearStyles}
`;

export const MediaOverlayRadial = styled.div`
  position: absolute;
  top: 90px;
  left: 50%;
  transform: translate(-50%);
  width: 80%;
  height: 313px;
  border-radius: 100%;
  opacity: 0.5;
  background: ${COLORS.BLACK_100};
  filter: blur(200px);

  ${MEDIA_QUERY.MOBILE} {
    top: 110px;
    height: 248px;
    opacity: 0.75;
    filter: blur(57px);
  }
`;

export const HeroVideo = styled.video`
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  ${MEDIA_QUERY.MOBILE} {
    display: none;
  }
`;

export const HeroVideoPoster = styled.img`
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  ${MEDIA_QUERY.MOBILE} {
    display: none;
  }
`;

export const HeroImageWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
`;

export const ContentContainer = styled.div`
  align-items: center;
  color: ${COLORS.WHITE_100};
  display: flex;
  flex-flow: column;
  justify-content: center;
  z-index: ${ZINDEX.middle};

  ${MEDIA_QUERY.MOBILE} {
    padding: ${SPACING.SM};
    width: 100%;
  }
`;

export const TitleWrapper = styled.div`
  margin-top: auto;
  text-align: center;

  ${MEDIA_QUERY.MOBILE} {
    position: absolute;
  }
`;

export const Subtitle = styled(H.Title2)`
  max-width: 520px;
  margin: auto;

  ${MEDIA_QUERY.MOBILE} {
    max-width: 335px;
  }
`;

export const Title = styled(H.TitleAccent1)`
  padding-bottom: ${SPACING.S16};
  font-weight: ${FONT.WEIGHT.MEDIUM};

  ${MEDIA_QUERY.MOBILE} {
    max-width: 335px;
    ${FONT.textSizeUltraCompact(FONT.SIZE.LARGER)}
  }
`;

export const ButtonWrapper = styled.div`
  column-gap: ${SPACING.S16};
  display: flex;
  justify-content: center;
  margin-top: 56px;
  width: 320px;

  ${MEDIA_QUERY.MOBILE} {
    column-gap: ${SPACING.XS};
    margin-bottom: 0;
    margin-top: auto;
    width: 100%;
  }
`;

export const DownArrowWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin-bottom: auto;
  margin-top: 80px;
  row-gap: ${SPACING.SM};

  ${MEDIA_QUERY.MOBILE} {
    display: none;
  }
`;

const bounce = keyframes`
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
`;

export const IconButton = styled(ButtonV3)`
  padding: 16px !important;
  animation: ${bounce} 2s 3;
`;
