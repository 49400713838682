import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { COLORS3 as COLORS, FONT, MEDIA_QUERY, SPACING } from '@zola/zola-ui/src/styles/emotion';

export const SectionWrapper = styled.span`
  -webkit-overflow-scrolling: touch;
  display: grid;
  grid-template-columns: 83.3% 16.7%;
  width: 100vw;
  border-bottom: 1px solid ${COLORS.BLACK_030};

  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    display: flex;
    flex-flow: column;
  }
  max-width: 1920px;

  ${MEDIA_QUERY.DESKTOP_MASSIVE} {
    margin-left: auto;
    margin-right: auto;
    border-left: 1px solid ${COLORS.BLACK_030};
    border-right: 1px solid ${COLORS.BLACK_030};
  }
`;

export const ScrollArea = styled.div`
  flex: 1;

  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    flex: 0;
  }
`;

export const Highlight = styled.div`
  display: grid;
  grid-template-columns: 500px 1fr;
  height: calc(100vh - 130px);
  min-height: 730px;
  padding: ${SPACING.LG} 0 ${SPACING.XL};

  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    height: fit-content;
    min-height: auto;
  }

  ${MEDIA_QUERY.DESKTOP_LG} {
    grid-template-columns: 520px 1fr;
  }

  ${MEDIA_QUERY.TABLET} {
    grid-template-columns: minmax(430px, 60%);
    grid-template-rows: 1fr 550px;
    justify-content: center;
    padding: 51px 0 ${SPACING.XL};
    row-gap: 60px;
  }

  ${MEDIA_QUERY.MOBILE} {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    max-width: 100%;
    padding: ${SPACING.LG} ${SPACING.MD} ${SPACING.XL};
    row-gap: ${SPACING.LG};
  }
`;

export const HighlightTextWrapper = styled.div`
  align-self: center;
  display: flex;
  flex-flow: column;
  max-width: 90%;
  padding: ${SPACING.XL} ${SPACING.LG};

  ${MEDIA_QUERY.EXCLUDE_DESKTOP_LG} {
    max-width: 100%;
    padding: ${SPACING.MD} ${SPACING.LG};

    h1 {
      font-size: ${FONT.FONT_SIZE_HUGE};
    }
  }

  ${MEDIA_QUERY.DESKTOP_XL} {
    max-width: 80%;
  }

  ${MEDIA_QUERY.TABLET} {
    justify-self: center;
    padding: 0;
    grid-column: 1;
    grid-row: 1;
  }

  ${MEDIA_QUERY.MOBILE} {
    padding: 0;
    grid-row: 1;
    max-width: fit-content;

    h2 {
      font-size: ${FONT.FONT_SIZE_LARGER};
    }
  }
`;

export const ProductTitle = styled.div`
  font-size: ${FONT.FONT_SIZE_SMALLER};
  padding-bottom: ${SPACING.S10};

  ${MEDIA_QUERY.DESKTOP} {
    display: none;
  }
`;

export const ProductDescription = styled.div`
  font-size: ${FONT.FONT_SIZE_REGULAR};
  padding: ${SPACING.MD} 0;

  ${MEDIA_QUERY.DESKTOP_LG} {
    font-size: ${FONT.FONT_SIZE_LARGE};
    padding: ${SPACING.LG} 0;
  }

  ${MEDIA_QUERY.TABLET} {
    font-size: ${FONT.FONT_SIZE_LARGE};
    padding: ${SPACING.LG} 0;
  }

  ${MEDIA_QUERY.MOBILE} {
    padding: ${SPACING.S24} 0;
    max-width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  column-gap: ${SPACING.XS};
  display: flex;
`;

export const motionStyles = css`
  height: 140%;

  width: 480px;
  position: relative;

  ${MEDIA_QUERY.MOBILE} {
    left: 0;
    width: 100%;
  }

  ${MEDIA_QUERY.MOBILE_XS} {
    position: relative;
    min-width: 375px;
  }
  img {
    height: 100%;
    width: 100%;
  }
`;

export const HighlightImageWrapper = styled.div`
  align-self: center;
  background-color: ${COLORS.WHITE_100};
  border-radius: 24px;
  -webkit-mask-image: -webkit-radial-gradient(white, black); // fixes safari issues
  mask-image: radial-gradient(white, black);
  height: 625px;
  justify-self: center;
  margin-left: ${SPACING.MD};
  overflow: hidden;
  position: relative;
  width: 480px;
  grid-row-start: 1;
  grid-row-end: span 2;

  ${MEDIA_QUERY.DESKTOP_LG} {
    margin-left: ${SPACING.LG};
  }

  ${MEDIA_QUERY.TABLET} {
    align-self: baseline;
    grid-column: 1;
    grid-row: 2;
    justify-self: baseline;
    margin: 0;
    width: 430px;
  }

  ${MEDIA_QUERY.MOBILE} {
    height: 238px;
    grid-row: 2;
    margin: 0;
    width: 100%;
  }
`;

const ForegroundImageWrapper = styled.div`
  bottom: 0;
  display: grid;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  ${MEDIA_QUERY.MOBILE} {
    scale: 0.6;
  }
`;

export const ForegroundImage = styled.img``;

export const GuestListForegroundWrapper = styled(ForegroundImageWrapper)`
  display: flex;
  justify-content: center;
  top: auto;
  bottom: ${SPACING.MD};

  ${MEDIA_QUERY.MOBILE} {
    bottom: 10px;
  }

  img {
    max-width: 379px;
  }
`;

export const WebsiteForegroundWrapper = styled(ForegroundImageWrapper)`
  align-content: center;
  justify-items: center;
  margin: auto;
  row-gap: ${SPACING.S16};

  > img:first-of-type {
    max-width: 295px;
  }
  > img:nth-of-type(2) {
    max-width: 297px;
  }
`;

export const UpscaledForegroundWrapper = styled(ForegroundImageWrapper)`
  ${MEDIA_QUERY.MOBILE} {
    scale: 1;
  }
`;

export const RegistryForegroundWrapper = styled(ForegroundImageWrapper)`
  align-content: center;
  column-gap: ${SPACING.S16};
  margin: auto;
  row-gap: ${SPACING.S16};

  > img {
    max-width: 182px;
    ${MEDIA_QUERY.MOBILE_XS} {
      max-width: 100%;
    }
  }

  > img:first-of-type {
    max-width: 166px;
    ${MEDIA_QUERY.MOBILE_XS} {
      max-width: 100%;
    }
  }
`;

export const VendorVenueForeground = styled.img`
  max-width: 350px;

  ${MEDIA_QUERY.MOBILE} {
    margin-top: -${SPACING.LG};
    margin-left: -${SPACING.MD};
  }
`;

export const PaperStampForeground = styled.img`
  scale: 0.6;

  ${MEDIA_QUERY.EXCLUDE_MOBILE} {
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
    scale: 1;
  }
  max-width: 180px;
  ${MEDIA_QUERY.MOBILE_XS} {
    max-width: 100%;
    scale: 0.7;
  }
`;

export const PaperFontsForeground = styled.img`
  align-self: end;
  grid-column: 2;
  margin-left: -2%;
  scale: 0.6;

  ${MEDIA_QUERY.EXCLUDE_MOBILE} {
    grid-column: 1;
    margin-bottom: 5%;
    margin-left: 6%;
    scale: 1;
  }

  max-width: 196px;

  ${MEDIA_QUERY.MOBILE_XS} {
    max-width: 100%;
    scale: 0.4;
  }
`;

export const RegistryItemForeground = styled.img`
  grid-column: 1;
  grid-row-start: 1;
  grid-row-end: span 2;
  justify-self: end;
`;

export const RegistryForegroundColumn2 = styled.img`
  grid-column: 2;
  justify-self: start;
`;

export const Sidebar = styled.div`
  background-color: ${COLORS.WHITE_100};
  display: flex;
  flex-flow: column;
  height: calc(100vh - 130px);
  margin: ${SPACING.LG};
  position: sticky;
  top: 120px;

  ${MEDIA_QUERY.DESKTOP} {
    margin: ${SPACING.MD};
  }

  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    height: auto;
    margin: ${SPACING.S32} 0 ${SPACING.LG} ${SPACING.MD};
    position: inherit;
    top: unset;
  }
`;

interface SidebarListItemProps {
  viewComplete: boolean;
}

export const SidebarListItem = styled.li<SidebarListItemProps>`
  border-bottom: 0.8px solid ${COLORS.BLACK_030};
  display: flex;
  opacity: 0.2;
  padding: ${SPACING.LG} 0;
  position: relative;
  width: 100%;

  @media (max-height: 800px) {
    padding: ${SPACING.MD} 0;
  }

  ${({ viewComplete }) =>
    viewComplete &&
    `
    animation: fade_anim 1s linear forwards;
    border-width: 0px;

    &:before {
      animation: border_anim 0.5s linear forwards;
      border-bottom: 0.8px solid ${COLORS.BLACK_030};
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      width: 0;
    }

    @keyframes fade_anim {
      0% {
        opacity: 0.2;
      }
      100% {
        opacity: 1;
      }
    }

    @keyframes border_anim {
      0% {
        opacity: 0.2;
        width: 0%;
      }
      100% {
        opacity: 1;
        width: 100%;
      }
    }
  `}
`;

export const SidebarList = styled.ul`
  display: flex;
  flex-flow: column;
  right: 0;

  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    display: none;
  }

  ${SidebarListItem}:last-of-type {
    border-bottom: none;

    &:before {
      border-bottom: none;
    }
  }
`;

export const MobileSidebarList = styled.div`
  display: flex;
  flex-flow: column;
  float: right;
  height: calc(100vh - 80px);
  padding-top: ${SPACING.MD};
  position: sticky;
  right: ${SPACING.LG};
  top: ${SPACING.MD};
  width: 8px;

  ${MEDIA_QUERY.DESKTOP} {
    display: none;
  }

  ${MEDIA_QUERY.MOBILE} {
    height: calc(100vh - 50px);
    right: ${SPACING.SM};
  }
`;

interface MobileListItemProps {
  backgroundColor: string;
  isInView?: boolean;
}

export const MobileListItem = styled.div<MobileListItemProps>`
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid ${COLORS.CLOUD_100};
  height: 8px;
  margin-bottom: ${SPACING.XXS};
  width: 8px;

  ${({ backgroundColor, isInView }) =>
    isInView &&
    `
    background-color: ${backgroundColor};
  `}
`;

export const HelpSection = styled.div`
  align-items: start;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin-top: auto;
  padding: ${SPACING.S32} 0px ${SPACING.LG};
  row-gap: ${SPACING.XXS};

  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    padding: 0;
    margin-top: 0;
  }
`;

export const HelpTitle = styled.h6`
  font-family: ${FONT.FONT_FAMILY_NEW_SPIRIT};
  font-size: ${FONT.FONT_SIZE_SMALL};
  letter-spacing: -0.01em;
`;

export const LinkWrapper = styled.div`
  align-items: center;
  column-gap: ${SPACING.XS};
  display: flex;
`;
