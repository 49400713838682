import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  ForegroundImagesWrapper,
  StyledForegroundImage,
} from '@zola/zola-ui/src/components/PreAuthV3/FeaturedImage.styles';
import { COLORS3 as COLORS, MEDIA_QUERY } from '@zola/zola-ui/src/styles/emotion';

export const DifferentiationWrapper = styled.span`
  > div {
    max-width: 1920px;
  }

  ${MEDIA_QUERY.DESKTOP_MASSIVE} {
    > div {
      margin-left: auto;
      margin-right: auto;
      border-left: 1px solid ${COLORS.BLACK_030};
      border-right: 1px solid ${COLORS.BLACK_030};
    }
  }

  ${MEDIA_QUERY.MOBILE} {
    ${ForegroundImagesWrapper} {
      justify-content: center;
    }

    picture {
      height: 100%;
      justify-content: center;
    }

    /* Other Differentiation included padding around foreground images, limiting height and width to not have images against bounds */
    ${StyledForegroundImage} {
      max-height: 90%;
      max-width: 90%;
    }
  }
`;

export const foregroundOverrides = css`
  ${ForegroundImagesWrapper} img {
    height: 90% !important;
    width: 90% !important;
    min-height: 10% !important;
    min-width: 10% !important;
  }
`;
