import { css } from '@emotion/react';
import { COLORS3, FONT, MEDIA_QUERY } from '@zola/zola-ui/src/styles/emotion';

export const VendorCrossLinksContainer = css`
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  padding: 40px;
  border-top: 1px solid ${COLORS3.BLACK_030};
  background-color: ${COLORS3.CLOUD_100};

  ${MEDIA_QUERY.DESKTOP_MASSIVE} {
    border-left: 1px solid ${COLORS3.BLACK_030};
    border-right: 1px solid ${COLORS3.BLACK_030};
  }

  ${MEDIA_QUERY.MOBILE} {
    padding: 24px;
  }
`;

export const Title = css`
  ${MEDIA_QUERY.TABLET} {
    font-size: ${FONT.SIZE.SMALLER};
  }

  ${MEDIA_QUERY.MOBILE} {
    font-size: ${FONT.SIZE.SMALLER};
  }
`;

export const List = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: ${FONT.SIZE.SMALLER};
`;

export const Link = css`
  color: ${COLORS3.BLACK_075};
`;
