import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  COLORS3 as COLORS,
  FONT,
  MEDIA_QUERY,
  SPACING,
  ZINDEX,
} from '@zola/zola-ui/src/styles/emotion';

export const SectionWrapper = styled.div`
  align-self: initial;
  background-color: ${COLORS.WHITE_100};
  display: grid;
  grid-template-columns: 25% 75%;

  ${MEDIA_QUERY.EXCLUDE_DESKTOP_LG} {
    display: flex;
    flex-flow: column;
    height: fit-content;
  }
`;

export const tabletOnly = css`
  ${MEDIA_QUERY.MOBILE} {
    display: none;
  }
  ${MEDIA_QUERY.DESKTOP} {
    display: none;
  }
`;
export const Sidebar = styled.div`
  border: solid 1px ${COLORS.BLACK_030};
  border-left-width: 0;
  display: flex;
  justify-items: center;

  ${MEDIA_QUERY.EXCLUDE_DESKTOP_LG} {
    border-width: 1px 0 0 0;
  }

  ${MEDIA_QUERY.MOBILE} {
    padding: ${SPACING.LG} ${SPACING.MD};
    width: 100%;
  }
`;

export const TitleWrapper = styled.div`
  color: ${COLORS.BLACK_100};
  margin: auto ${SPACING.LG};

  ${MEDIA_QUERY.EXCLUDE_DESKTOP_LG} {
    margin: ${SPACING.S16} auto;
    text-align: center;
    width: 400px;

    h2 {
      font-size: ${FONT.FONT_SIZE_LARGER};
    }
  }

  ${MEDIA_QUERY.MOBILE} {
    margin: 0;
    text-align: left;
  }
`;

export const Subtitle = styled.div`
  font-family: ${FONT.FONT_FAMILY_CIRCULAR};
  font-size: ${FONT.FONT_SIZE_LARGE};
  margin-top: ${SPACING.S32};

  ${MEDIA_QUERY.EXCLUDE_DESKTOP_LG} {
    font-size: ${FONT.FONT_SIZE_SMALL};
    margin-top: ${SPACING.S16};
  }

  ${MEDIA_QUERY.MOBILE} {
    display: block;
    font-size: ${FONT.FONT_SIZE_SMALL};
    margin-top: ${SPACING.S24};
  }
`;

export const Grid = styled.div`
  border-top: solid 1px ${COLORS.BLACK_030};
  display: grid;
  flex: 1;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);

  ${MEDIA_QUERY.DESKTOP} {
    div:nth-of-type(1),
    div:nth-of-type(4) {
      border-left: none;
    }
  }

  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);

    div:nth-of-type(1),
    div:nth-of-type(3),
    div:nth-of-type(5) {
      border-left: none;
    }
  }
`;

const gridHeaderStyles = css`
  align-items: center;
  font-size: ${FONT.FONT_SIZE_SMALLER};
  font-weight: ${FONT.FONT_WEIGHT_SEMI_BOLD};
  line-height: ${FONT.FONT_SIZE_REGULAR};

  ${MEDIA_QUERY.EXCLUDE_MOBILE} {
    font-size: ${FONT.FONT_SIZE_LARGE};
    font-weight: ${FONT.FONT_WEIGHT_REGULAR};
    line-height: ${FONT.FONT_SIZE_LARGER};
  }

  // intercept clicks so the entire grid is clickable
  &:before {
    bottom: 0;
    content: ' ';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: ${ZINDEX.middle};
  }

  :hover,
  :active,
  :focus,
  :focus-visible {
    color: ${COLORS.BLACK_075};
  }

  // Adjust the arrow icon to be inline with the text
  svg {
    vertical-align: middle;
    margin-right: ${SPACING.XXS};
    padding-bottom: 0.1em;
    transform: rotate(180deg);
  }
`;

export const GridHeader = styled.a`
  text-decoration: none;
  ${gridHeaderStyles}
`;

export const GridDescription = styled.div`
  font-size: ${FONT.FONT_SIZE_SMALLER};

  ${MEDIA_QUERY.MOBILE} {
    font-size: ${FONT.FONT_SIZE_SMALLER};
    color: ${COLORS.BLACK_075};
  }
`;

export const GridImageWrapper = styled.div`
  background-color: ${COLORS.BLACK_015};
  border-radius: 16px;
  height: 125px;
  -webkit-mask-image: -webkit-radial-gradient(white, black); // fixes safari issues
  mask-image: radial-gradient(white, black);
  width: 100%; // Fixes safari issues
  margin: auto auto 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  transition: scale 0.3s ease;

  > div {
    min-height: 100%;
  }

  ${MEDIA_QUERY.EXCLUDE_MOBILE} {
    border-radius: 24px;
    height: 240px;
  }

  ${MEDIA_QUERY.TABLET} {
    margin-top: ${SPACING.MD};
  }

  ${MEDIA_QUERY.DESKTOP} {
    margin-top: auto;
  }
`;

export const GridBackgroundWrapper = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;

  z-index: ${ZINDEX.top};
  width: 100%;
  height: 100%;
  ${MEDIA_QUERY.EXCLUDE_MOBILE} {
    height: 140%;
  }

  > div {
    min-height: 100%;
    width: 100%;
    position: relative;
  }

  img {
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
    width: inherit;

    ${MEDIA_QUERY.DESKTOP} {
      max-width: fit-content;
      width: 170%;
    }

    ${MEDIA_QUERY.DESKTOP_XL} {
      max-width: 100%;
      width: inherit;
    }
  }
`;

export const GridBox = styled.div`
  display: flex;
  align-items: left;
  justify-content: space-between;
  border-bottom: solid 1px ${COLORS.BLACK_030};
  border-left: solid 1px ${COLORS.BLACK_030};
  flex-flow: column;
  height: 100%;
  min-height: 250px;
  padding: ${SPACING.S16} ${SPACING.SM};
  position: relative;
  width: 100%;

  ${MEDIA_QUERY.TABLET} {
    padding: ${SPACING.LG};
  }

  ${MEDIA_QUERY.DESKTOP} {
    min-height: 400px;
    padding: ${SPACING.LG};
    width: auto;
  }

  :hover,
  :active,
  :focus {
    ${GridImageWrapper} {
      scale: 1.03;
    }
  }
`;

export const GridForegroundImage = styled.img`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  z-index: ${ZINDEX.top};
`;

export const guestListFgOverrides = css`
  margin: 0 auto 5%;
  max-height: 22%;
  max-width: 75%;

  ${MEDIA_QUERY.EXCLUDE_MOBILE} {
    margin-bottom: 2%;
  }

  ${MEDIA_QUERY.DESKTOP} {
    max-height: 27%;
  }

  ${MEDIA_QUERY.DESKTOP_LG} {
    max-height: 22%;
    max-width: 80%;
  }

  ${MEDIA_QUERY.DESKTOP_XL} {
    max-width: 100%;
  }
`;

export const websiteFgOverrides = css`
  max-height: 85%;
  max-width: 100%;
  margin: auto;
  top: 0;
`;

export const vendorFgOverrides = css`
  max-width: 100%;
`;

export const paperFgOverrides = css`
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  max-width: 70%;

  ${MEDIA_QUERY.DESKTOP} {
    margin-bottom: -5%;
    max-width: 100%;
  }

  ${MEDIA_QUERY.MOBILE_XS} {
    max-width: 100px;
  }

  ${MEDIA_QUERY.DESKTOP_XL} {
    max-width: 80%;
  }
`;

export const registryFgOverrides = css`
  top: 0;
  margin: auto;
  max-height: 77%;
  max-width: 65%;
`;

export const adviceFgOverrides = css`
  margin: 0 auto 2%;
  max-height: 50%;
  max-width: 75%;
  scale: 0.9;

  ${MEDIA_QUERY.EXCLUDE_MOBILE} {
    scale: 1;
  }

  ${MEDIA_QUERY.DESKTOP_LG} {
    max-height: 50%;
    max-width: 60%;
  }
`;
